import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchedFormGroups: "",
  formGroupList: [],
};

export const slice = createSlice({
  name: "FormGroups",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    searchFormGroups: (state, action) => {
      state.searchedFormGroups = action.payload;
    },
    setFormGroupList: (state, action) => {
      state.formGroupList = action.payload;
    },
  },
});

export const { searchFormGroups, setFormGroupList } = slice.actions;

export default slice.reducer;
