import { IconButton, Menu } from "@mui/material";
import React from "react";

function NavBarMenu({ handleOpen, handleClose, anchorEl, children, Icon }) {
  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpen}
        color="inherit"
      >
        <Icon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          display: { xs: "block", md: "none" },

          "& .MuiPaper-root": {
            backgroundColor: "#305C53",
          },
        }}
      >
        {children}
      </Menu>
    </>
  );
}

export default NavBarMenu;
