import React, { useState, useEffect } from "react";
import { TextField, Button, Switch, Typography, Grid } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { DragNDrop, CheckBoxSelect } from "../csv";
import pickSelectedData from "../../utils/pickSelectedData";
import {useCookies} from "react-cookie"
import {SSO_TOKEN_NAME} from "../../../constants/cookies"
import {useSelector} from "react-redux"

function FormField({
  parameter,
  index,
  formik,
  fileName,
  setFileName,
  noOfTransactions,
  setNoOfTransactions,
  setIsSplitTransaction,
}) {
  const [cookies] = useCookies([SSO_TOKEN_NAME])
  const theme = useTheme();
  const [dragOpen, setDragOpen] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const handleClickOpen = () => {
    setDragOpen(true);
  };
  const {currentNetwork} = useSelector((state) => state.WalletInfo);

  const handleDragClose = (value) => {
    setDragOpen(false);
    if (value) {
      setCsvData(value);
      setSelectOpen(true);
    }
  };

  const handleSelectClose = (value) => {
    setSelectOpen(false);

    if (value) {
      setSelectedData([]);

      csvData.map((data) =>
        setSelectedData((prevData) => [
          ...prevData,
          pickSelectedData(data, ...value),
        ])
      );
      if (noOfTransactions > 0 && isChecked) {
        setIsSplitTransaction(true);
      } else {
        setIsSplitTransaction(false);
      }
    }
  };

  useEffect(() => {
    if (selectedData && selectedData?.length !== 0) {
      let tempValue = "";
      selectedData.forEach((data, index) => {
        const dataParamList = Object.entries(data);
        dataParamList.map((name, index) =>
          index === dataParamList.length - 1
            ? (tempValue += data[name[0]])
            : (tempValue += data[name[0]] + ",")
        );
        if (index !== selectedData.length - 1) tempValue += "\n";
      });

      formik.setValues((prevState) => ({
        ...prevState,
        [parameter.id]: tempValue,
      }));
    }
  }, [selectedData, parameter]);

  useEffect(() => {
    if (parameter.name === "sso") {
      formik.values[parameter.id] = `${process.env.REACT_APP_SSO_DOMAIN},${cookies[SSO_TOKEN_NAME]}`
    }
    if (parameter.name === "alchemy") {
      console.log(process.env.REACT_APP_ALCHEMY_KEY)
      const alchemyKeys = JSON.parse(process.env.REACT_APP_ALCHEMY_KEY)
      const alchemy = alchemyKeys[currentNetwork]
      formik.values[parameter.id] = `${alchemy.apikey},${alchemy.network},${currentNetwork},${alchemy.gasStation}`
    }
    if (parameter.value != null) {
        formik.values[parameter.id] = parameter.value;
    }
  }, [parameter, cookies]);

  let error =
    formik.errors.hasOwnProperty(parameter.id) &&
    Boolean(formik.errors[parameter.id]);
  if (parameter.hidden) {
      return (<></>)
  }
  return (
    <>
      {parameter.type !== "boolean" ? (
        <TextField
          variant="filled"
          key={index}
          label={parameter.name}
          name={parameter.id}
          type={parameter.type ? parameter.type : "text"}
          placeholder={parameter.description}
          value={formik.values[parameter.id] ? formik.values[parameter.id] : ""}
          onChange={formik.handleChange}
          error={error}
          InputLabelProps={{
            shrink: true,
          }}
          multiline={parameter.multiline ? true : false}
          maxRows={10}
          InputProps={
            parameter.InputProps
              ? {
                  endAdornment: (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleClickOpen}
                    >
                      import
                    </Button>
                  ),
                }
              : {}
          }
          helperText={(error && formik.errors[parameter.id]) || fileName}
          fullWidth
        />
      ) : (
        <Grid container>
          <Grid item>
            <FormControlLabel
              value={formik.values[parameter.id]}
              control={
                <Switch
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: theme.border.method,
                      "&:hover": {
                        backgroundColor: alpha(theme.border.method, 0.2),
                      },
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: theme.border.method,
                    },
                  }}
                  label={parameter.name}
                  checked={formik.values[parameter.id]}
                  onChange={() =>
                    formik.setValues((prevState) => ({
                      ...prevState,
                      [parameter.id]: !formik.values[parameter.id],
                    }))
                  }
                />
              }
              label={parameter.name}
              labelPlacement="top"
            />
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              color={formik.values[parameter.id] ? "success.main" : "error"}
            >
              {formik.values[parameter.id] ? "True" : "False"}
            </Typography>
          </Grid>
        </Grid>
      )}
      <DragNDrop
        open={dragOpen}
        onClose={handleDragClose}
        fileType=".csv"
        setFileName={setFileName}
      />
      <CheckBoxSelect
        description={parameter.description}
        open={selectOpen}
        onClose={handleSelectClose}
        data={csvData}
        noOfTransactions={noOfTransactions}
        setNoOfTransactions={setNoOfTransactions}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
      />
    </>
  );
}

export default FormField;
