const getParameters = (parameters) => {
  return parameters.map((parameter) => getParameter(parameter));
};
export default getParameters;

const getParameter = (parameter) => {
  switch (parameter.type) {
    case "string":
      return {
        ...parameter,
        id: parameter.name,

        validationType: "string",
        validations: [
          {
            type: "min",
            params: [1, "string cannot be less than 1 character"],
          },
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
      };
    case "bigNumber":
      return {
        ...parameter,
        id: parameter.name,
        validationType: "string",

        type: "number",
        validations: [
          {
            type: "matches",
            params: [/^\d+$/, "enter a valid integer number"],
          },
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
      };
    case "csv":
      return {
        ...parameter,
        id: parameter.name,
        validationType: "string",
        validations: [
          // {
          //   type: "matches",
          //   params: [
          //     /^([0-9a-z]+(,[0-9a-z]+[\r\n]*)*)*$/i,
          //     "must be in the format string or string,...",
          //   ],
          // },
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
        InputProps: true,
        multiline: true,
      };
    case "object":
      return {
        ...parameter,
        id: parameter.name,
        validationType: "string",
        validations: [
          // {
          //   type: "matches",
          //   params: [
          //     /^([0-9a-z]+(,[0-9a-z]+[\r\n]*)*)*$/i,
          //     "must be in the format string or string,...",
          //   ],
          // },
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
      };
    case "timeStamp":
      return {
        ...parameter,
        id: parameter.name,
        validationType: "string",
        validations: [
          {
            type: "matches",
            params: [/^\d+$/, "enter a valid integer number"],
          },
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
      };
    case "integer":
      return {
        ...parameter,
        id: parameter.name,
        validationType: "string",
        type: "number",

        validations: [
          {
            type: "matches",
            params: [/^[0-9]+$/, "enter a valid integer number"],
          },
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
      };
    case "float":
      return {
        ...parameter,
        id: parameter.name,
        validationType: "number",
        type: "number",
        validations: [
          {
            type: "matches",
            params: [
              /[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)/,
              "needs to be a float number",
            ],
          },
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
      };
    case "boolean":
      return {
        ...parameter,
        id: parameter.name,
        validationType: "boolean",
        validations: [
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
      };
    case "address":
      return {
        ...parameter,
        id: parameter.name,
        validationType: "string",
        validations: [
          {
            type: "matches",
            params: [
              /0[x][0-9a-z]+$/i,
              "It needs to be in the format '0x' + characters",
            ],
          },
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
      };
    case "arrayInt":
      return {
        ...parameter,
        id: parameter.name,
        validationType: "string",
        validations: [
          {
            type: "matches",
            params: [
              /^[\d]+(,[\d]+)*$/,
              "must be in the format integer or integer,...",
            ],
          },
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
      };
    case "arrayString":
      return {
        ...parameter,
        id: parameter.name,
        validationType: "string",
        validations: [
          {
            type: "matches",
            params: [
              /^[0-9a-zA-Z\-@.]+(,[0-9a-zA-Z\-@.]+)*$/,
              "must be in the format string or string,...",
            ],
          },
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
      };
    case "arrayAddress":
      return {
        ...parameter,
        id: parameter.name,
        validationType: "string",
        validations: [
          {
            type: "matches",
            params: [
              /^0[x][0-9a-z]+(,0[x][0-9a-z]+)*$/i,
              "must be in the format 0x2312 or 0x0x2312,...",
            ],
          },
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
      };
    default:
      return {
        ...parameter,
        id: parameter.name,
        validationType: "string",
        validations: [
          {
            type: "required",
            params: ["this field is required"],
          },
        ],
      };
  }
};