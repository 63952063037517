import {isInt} from "../regex"
const getItems = (data, parameter) => {
  let tempData
  let splitData = data.split(",")
  if (splitData.length !== 0) {
    tempData = splitData
  } else {
    tempData = [data]
  }
  let keys = parameter.description.split(",");
    let tempKey = {};
    keys.forEach((key, index) =>
      Object.assign(tempKey, {
        [key]: isInt(tempData[index]) ? parseInt(tempData[index]) : tempData[index]
      })
    );
    return tempKey;
};

export default getItems;
