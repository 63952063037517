import React, { useState, useEffect } from "react";
import { FormGroup } from "../../components/forms";
import { Box } from "@mui/system";
import SearchBar from "./SearchBar";
/**
 * FormGroupList
 * @param {configuration} config
 * @returns {elements} - renders the form group list component
 */
function FormGroupList({ config }) {
  const { methodGroups } = config;
  const [formGroupList, setFormGroupList] = useState([]);
  useEffect(() => setFormGroupList(methodGroups), [config]);

  /**
   * getQueriedFormGroupList
   * @param {string} searchTerm
   * @param {array<methodGroup>} List
   * @returns {array<methodGroup>} returns an array of method groups that matches the query
   */
  const getQueriedFormGroupList = (searchTerm, List) => {
    const searchQuery = searchTerm.toLowerCase();
    let tempList = [];
    const listClone = List.map((methodGroup) => ({
      ...methodGroup,
    }));

    listClone.forEach((formGroup) => {
      if (formGroup.name.toLowerCase().includes(searchQuery))
        return tempList.push(formGroup);
      else {
        formGroup.methods = formGroup.methods.filter((method) =>
          method.name.toLowerCase().includes(searchQuery)
        );
        if (formGroup.methods.length > 0) return tempList.push(formGroup);
      }
    }, []);

    return tempList;
  };

  const onSearchSubmit = (term) => {
    setFormGroupList(getQueriedFormGroupList(term, methodGroups));
  };
  const clearResults = () => {
    setFormGroupList(methodGroups);
  };
  // const uniqueKey = config.title.length;
  return (
    <Box
      sx={{
        my: { xs: 1, sm: 3 },
        mx: { xs: 1.5, sm: 4 },
      }}
    >
      <Box sx={{ mb: { xs: 2, sm: 4 } }}>
        <SearchBar
          onSearchSubmit={onSearchSubmit}
          clearResults={clearResults}
        />
      </Box>
      <Box>
        {formGroupList.map((methodGroup, index) => (
          <FormGroup
            key={index + config.title}
            methodGroup={methodGroup}
          ></FormGroup>
        ))}
      </Box>
    </Box>
  );
}

export default FormGroupList;
