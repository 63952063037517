import {isInt} from "../regex"

const getItems = (data, parameter) => {
  let tempData = data
    .map((dataItem) => {
      if (dataItem.split(",")[1]) return dataItem.split(",");
    })
    .filter((v) => v !== undefined);

  let keys = parameter.description.split(",");

  return tempData.map((temp) => {
    let tempKey = {};
    keys.forEach((key, index) =>
      Object.assign(tempKey, {
        [key]: isInt(temp[index]) ? parseInt(temp[index]) : temp[index]
      })
    );

    return tempKey;
  });
};

export default getItems;
