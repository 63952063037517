import {Box} from "@mui/material"
import React from "react";
import { InputSelect, ReadOnlyText } from "../../../components/inputs";
import NavbarAuth from "./NavbarAuth"
import NetworkSelect from "./NetworkSelect";
import { shortenAddressString } from "../../../utils/strings";

function NavBarWalletInfo({
  isCorrectNetwork,
  currentNetwork,
  networks,
  handleChange,
  account,
  mainAccounts,
  otherAccounts,
  additionalSx,
}) {
  return (
    <Box>
      <NavbarAuth></NavbarAuth>
      <InputSelect
        isNative={true}
        label="Contract Addressess"
        additionalSx={additionalSx}
      >
        <optgroup label="main">
          <option>{shortenAddressString(mainAccounts)}</option>
        </optgroup>
        {otherAccounts.length !== 0 ? (
          <optgroup label="other">
            {otherAccounts.map((contractAddress, index) => (
              <option key={index}>
                {shortenAddressString(contractAddress)}
              </option>
            ))}
          </optgroup>
        ) : (
          ""
        )}
      </InputSelect>
      <ReadOnlyText
        text={account}
        label="User Address"
        additionalSx={additionalSx}
      />
      <NetworkSelect
        label="Networks"
        isConnected={isCorrectNetwork}
        networkList={networks}
        handleChange={handleChange}
        currentNetwork={currentNetwork}
        additionalSx={additionalSx}
      />
    </Box>
  );
}

export default NavBarWalletInfo;
