import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import BackgroundImage from "../../assets/bg-effect.png";

function ConnectionError({ isAccountConnected, isCorrectNetwork }) {
  return (
    <Box
      sx={{
        color: "white",
        backgroundImage: `url(${BackgroundImage})`,

        border: `2px solid red`,
        my: 10,
        mx: { xs: 1, sm: 6 },
        p: { xs: 2, sm: 4 },
      }}
    >
      <Typography
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="error"
        align="center"
      >
        {!isAccountConnected && "Account not connected"}
      </Typography>
      <Typography
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="error"
        align="center"
      >
        {!isCorrectNetwork && "Wrong network detected"}
      </Typography>
    </Box>
  );
}

export default ConnectionError;
