import React, { useState, useMemo, useContext } from "react";
import { Box } from "@mui/system";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { NavBar } from "./navbar";
import MyContext from "../../context/myContext";
import FormGroupList from "./FormGroupList";
import SDKPanelDescription from "./SDKPanelDescription";
import { ConnectionError } from "../../components/responses";

import { Provider } from "react-redux";
import { store } from "../../redux/store";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";
import {
  setConfig,
  setCurrentConfig,
} from "../../redux/slices/WalletInfo.slice";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "black",
        },
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: "borderBox",
        },
        html: {
          backgroundColor: "black",
        },
        iframe: {
          pointerEvents: "none",
        },
      },
    },
  },
  bg: {
    main: "#white",
    methodGroup: "#80FAE2",
    method: "#E8F6EF",
    parameter: "#E8F6EF",
    wallet: "#80FAE2",
    button: {
      primary: "#49CB90",
      secondary: "#2ea06c",
    },
  },
  border: {
    method: "#49CB90",
    methodGroup: "#80FAE2",
    wallet: "#80FAE2",
  },
  color: {
    header: "white",
    methodGroup: "#80FAE2",
  },
  response: {
    error: "#d13c3c",
    success: "#3e8028",
  },
});

function RenderNavBarAndError({
  config,
  onProviderMount,
  currentConfig,
  setCurrentConfig,
}) {
  const {
    isClientReady,
    isAccountConnected,
    isCorrectNetwork,
    currentAccount,
  } = useSelector((state) => state.WalletInfo);
  const dispatch = useDispatch();
  useMemo(() => dispatch(setConfig(config)), [config]);

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "0px",
          left: "0px",
          zIndex: "10",
          width: "100%",
          height: { xs: "70px", sm: "85px" },
          background: `linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 80%, rgba(7, 9, 17, 0) 100%)`,
        }}
      />

      <NavBar
        onProviderMount={onProviderMount}
        currentConfig={currentConfig}
        setCurrentConfig={setCurrentConfig}
      />

      {!isClientReady && (
        <ConnectionError
          isAccountConnected={isAccountConnected}
          isCorrectNetwork={isCorrectNetwork}
        />
      )}
    </>
  );
}

function RenderSDKPanel({ currentConfig }) {
  const { isClientReady, currentAccount, isCorrectNetwork } = useSelector(
    (state) => state.WalletInfo
  );
  // TODO: switch this to react redux instead if possible
  const [methodHandling, setMethodHandling] = useState();
  useMemo(() => {
    if (isClientReady) {
      const formatError = currentConfig.configuration.formatError
        ? currentConfig.configuration.formatError
        : (e) => e;
      const executeMethod = currentConfig.executeMethod;
      const hasRole = currentConfig.hasRole
        ? currentConfig.hasRole
        : () => {
            return new Promise(() => true);
          };

      setMethodHandling({
        executeMethod,
        formatError,
        hasRole,
      });
    }
  }, [currentAccount, isCorrectNetwork, currentConfig, isClientReady]);
  return (
    <MyContext.Provider value={{ methodHandling, setMethodHandling }}>
      <Box
        className="App"
        sx={{
          backgroundColor: "black",
        }}
      >
        {isClientReady && (
          <SDKPanelDescription configuration={currentConfig.configuration} />
        )}

        {isClientReady && (
          <FormGroupList config={currentConfig.configuration} />
        )}
        <ToastContainer position="top-right" />
      </Box>
    </MyContext.Provider>
  );
}
/**
 * @typedef {object} network
 * @property {string} key - used to identify the network on the client
 * @property {string} name - name of the network i.e. Polygon Mainnet
 * @property {array<string>} rpc
 */

/**
 * This function determines whether the client/sdk is ready and show be displayed based on the wallet provider and the current network key
 * @callback onProviderMountCallBack
 * @param {object} provider - wallet provider
 * @param {network} network - network object containing a key representing the network id on the polygon network
 * @returns {bool} - determines whether or not to dispaly the sdk
 */
/**
 * @callback formatErrorCallBack
 * @param {string} errorType
 *@returns {string}
 */
/**
 * @callback formatResponseCallBack
 * @param {*} data
 * @returns {string | object} will return the data as a string or object
 *
 */
/**
 * @typedef {object} parameter
 * @property {string} name
 * @property {string|undefined} description
 * @property {string} type
 */
/**
 * @typedef {object} method
 * @property {string} name
 * @property {string|undefined} description
 * @property {array<parameter>} parameters
 * @property {array<role>} restricted
 */

/**
 * @typedef {object} methodGroup
 * @property {string} name - The name of the Method Group
 * @property {string|undefined} description - A description of the MethodGroup
 * @property {array<method>} methods - a array containing all the methods for the method group
 *
 */

/**
 * This contains the configuration for the SDK
 * @typedef {object} configuration
 * @property {string} title - The title of the SDK
 * @property {string} description - A description of the SDK
 * @property {array<network>} networks - A list of networks that the SDK supports
 * @property {formatErrorCallBack|undefined} formatError
 * @property {array<role>} roles
 * @property {array<methodGroup>} methodGroups
 */
/**
 * @callback executeMethodCallBack
 * @param {string} methodName - The name of the method being executed (method property in the method object)
 * @param {array} parameters - an array of all the parameters needed in the correct order
 * @returns {Promise} - returns a promise that will give the response if it executes correctly or give the error to display
 */

/**
 * @typedef {object }role
 * @property {string} name - The name of the role i.e. MAINTAIN_ROLE
 * @property {string} role - role identifier i.e 0x0000000001
 *
 */

/**
 * @callback hasRoleCallBack
 * @param {role} role
 * @returns {Promise} - determines whether the user has that role or not
 */

/**
 * @callback onNetworkChangeCallBack
 * @param {network} network
 * @returns {void}
 */
/**
 * This contains all the data for the SDK
 * @typedef {object} SDK
 * @property {configuration} configuration
 * @property {executeMethodCallBack} executeMethod
 * @property {Callback} getContractAddress
 * @property {Callback} getOtherContractAddress
 * @property {hasRoleCallBack} hasRole
 * @property {Callback} onAccountChange
 * @property {onNetworkChangeCallBack} onNetworkChange
 *
 *
 */

/**
 * This contains all the SDKs
 * @typedef {object} config
 * @property {SDK} collectibles
 * @property {SDK} assets
 * @property {SDK} store
 *
 */

/**
 * SDK Panel
 * @param {onProviderMountCallBack} onProviderMount - This determines if client/sdk is ready once the wallet provider has mounted
 * @param {config} config - This contains all the SDKs
 * @returns {elements} generates the NavBar and FormGroupList Components
 */
export function SDKPanel({ onProviderMount, config }) {
  // const [isClientReady, setIsClientReady] = useState(false);
  const [currentConfig, setCurrentConfig] = useState(
    config[Object.keys(config)[0]]
  );
  const getLibrary = (provider) => {
    return new Web3Provider(provider);
  };

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <RenderNavBarAndError
            config={config}
            onProviderMount={onProviderMount}
            currentConfig={currentConfig}
            setCurrentConfig={setCurrentConfig}
          />
          <RenderSDKPanel currentConfig={currentConfig} />
        </ThemeProvider>
      </Provider>
    </Web3ReactProvider>
  );
}

SDKPanel.propTypes = {
  config: PropTypes.object.isRequired,
};
