import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";

import { alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";

function SearchBar({ onSearchSubmit, clearResults }) {
  const [term, setTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(term);

  // update 'term' value after 1 second from the last update of 'debouncedTerm'
  useEffect(() => {
    const timer = setTimeout(() => setTerm(debouncedTerm), 500);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  // submit a new search
  useEffect(() => {
    if (term !== "") {
      onSearchSubmit(term);
    } else {
      clearResults();
    }
  }, [term]);
  return (
    <TextField
      sx={{
        borderRadius: 1,
        backgroundColor: alpha("#FFFFFF", 0.8),
        "&:hover": {
          backgroundColor: alpha("#FFFFFF", 0.9),
        },
      }}
      variant="outlined"
      placeholder="Search Bar"
      onChange={(e) => setDebouncedTerm(e.target.value)}
      value={debouncedTerm}
      InputProps={{
        startAdornment: <SearchIcon sx={{ mr: 1 }} />,
      }}
      fullWidth
    />
  );
}

export default SearchBar;
