import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";

export const useIsCorrectNetwork = (networks) => {
  const { chainId, library: provider } = useWeb3React();
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false);

  useEffect(() => {
    if (provider) {
      provider?.getNetwork().then(() => {
        setIsCorrectNetwork(
          networks.find((network) => network.chainId === chainId)
        );
      });
    }
  }, [provider, chainId]);

  return isCorrectNetwork;
};
