import { splitByNewLine } from "../strings";

const splitRowsPerTransaction = (data, noOfTransactions, parameters) => {
  const parameter = parameters.find((parameter) => parameter.type === "csv");

  const tempRows = splitByNewLine(data[parameter.name]);

  let tempData = {};

  Object.keys(data).forEach((key, index) => {
    if (key === parameter.name) {
      let counter = 1;
      for (let i = 0; i < tempRows.length; i += parseInt(noOfTransactions)) {
        const slicedData = tempRows.slice(i, i + parseInt(noOfTransactions));
        Object.assign(tempData, { ["file" + counter++]: slicedData });
      }
    } else {
      Object.assign(tempData, { [key]: data[key] });
    }
  });
  return tempData;
};
export default splitRowsPerTransaction;
