import { getData as csvGetData } from "./csv";
import { getData as objectGetData } from "./object";
import getArrayIntData from "./getArrayIntData";
import getArrayStringData from "./getArrayStringData";
///combines objects of parameters into an array of parameters
const getOrderedArray = (values, parameter) => {
  if (parameter.type === "csv") {
    return csvGetData(values[parameter.name], parameter);
  } else if (parameter.type === "object") {
    return objectGetData(values[parameter.name], parameter);
  } else if (parameter.type === "arrayInt") {
    return getArrayIntData(values[parameter.name]);
  } else if (
    parameter.type === "arrayString" ||
    parameter.type === "arrayAddress"
  ) {
    return getArrayStringData(values[parameter.name]);
  } else {
    return values[parameter.name];
  }
};
export default getOrderedArray;
