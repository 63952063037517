import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import {
  Button,
  Checkbox,
  Dialog,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useTheme, alpha } from "@mui/material/styles";

function CheckBoxSelect({
  open,
  onClose,
  data,
  noOfTransactions,
  setNoOfTransactions,
  isChecked,
  setIsChecked,
  description = "",
}) {
  const [checkedArray, setCheckedArray] = useState([]);

  useEffect(() => {
    setCheckedArray([]);
  }, [open]);
  const handleClose = (s) => {
    onClose(s);
  };
  const handleChange = (event) => {
    let tempCheckedArray = [...checkedArray];

    if (event.target.checked) {
      tempCheckedArray.push(event.target.name);
      setCheckedArray((prev) => [...tempCheckedArray]);
    } else {
      tempCheckedArray = checkedArray.filter(
        (name) => name !== event.target.name
      );
      setCheckedArray((prev) => [...tempCheckedArray]);
    }
  };
  const error = checkedArray.length < 1 || (isChecked && noOfTransactions < 1);

  const theme = useTheme();
  return (
    <Dialog open={open} onClose={() => handleClose(null)}>
      <Box>
        <FormControl
          required
          error={error}
          sx={{ m: 3, maxWidth: "280px" }}
          component="fieldset"
          variant="standard"
        >
          <FormLabel component="legend">
            Pick Atleast One <br /> Caution: Order of selection matters
          </FormLabel>
          Column Order:
          <Typography
            variant="subtitle"
            style={{
              wordWrap: "break-word",
              color: "grey",
              fontStyle: "italic",
            }}
          >
            {description}
          </Typography>
          <FormGroup>
            {data &&
              Object.getOwnPropertyNames(data[0]).map((name) => {
                return (
                  <Box display="flex" alignItems="center" key={name}>
                    {checkedArray.length !== 0 ? (
                      <Typography variant="subtitle1">
                        {checkedArray.indexOf(name) !== -1
                          ? checkedArray.indexOf(name) + 1
                          : ""}
                      </Typography>
                    ) : (
                      ""
                    )}
                    <FormControlLabel
                      sx={{ pl: 1 }}
                      key={name}
                      control={
                        <Checkbox
                          checked={
                            checkedArray.indexOf(name) !== -1 ? true : false
                          }
                          onChange={handleChange}
                          name={name}
                          sx={{
                            "&.Mui-checked": {
                              color: theme.border.method,
                            },
                          }}
                        />
                      }
                      label={name}
                    />
                  </Box>
                );
              })}
            <FormControlLabel
              sx={{ justifyContent: "flex-end", m: 0 }}
              value="start"
              control={
                <Switch
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: theme.border.method,
                      "&:hover": {
                        backgroundColor: alpha(theme.border.method, 0.2),
                      },
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: theme.border.method,
                    },
                  }}
                  // label={parameter.name}
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
              }
              // label={parameter.name}
              label="Limit Transactions?"
              labelPlacement="start"
            />
            {isChecked && (
              <TextField
                variant="filled"
                label="Number of Transactions Per File"
                type="number"
                value={noOfTransactions}
                onChange={(e) => setNoOfTransactions(e.target.value)}
                error={noOfTransactions < 1}
                InputLabelProps={{
                  shrink: true,
                }}
                // multiline={parameter.multiline ? true : false}
                helperText={noOfTransactions < 1 && "Must be greater than 0"}
                fullWidth
              />
            )}
            {data && checkedArray.length > 0 && !error && (
              <Button
                variant="contained"
                size="medium"
                onClick={() => handleClose(checkedArray)}
                sx={{
                  mt: 1,
                  backgroundColor: theme.bg.button.primary,

                  "&:hover": {
                    backgroundColor: theme.bg.button.secondary,
                  },
                }}
              >
                Submit
              </Button>
            )}
          </FormGroup>
        </FormControl>
      </Box>
    </Dialog>
  );
}

export default CheckBoxSelect;
