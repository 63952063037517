import {Link} from "@mui/material"
import React, {useEffect, useState} from "react"
import {SSO_TOKEN_NAME} from "../../../../constants/cookies"
import {useCookies} from "react-cookie"

function NavbarAuth() {
    const [jwt, setJwt] = useState()
    const authUrl = process.env.REACT_APP_AUTH_DOMAIN + "/login?redirect_url=" + window.location.href
    const accountUrl = process.env.REACT_APP_ACCOUNT_DOMAIN + "/settings"
    const [cookies] = useCookies([SSO_TOKEN_NAME])

    useEffect(() => {
        setJwt(cookies[SSO_TOKEN_NAME])
    }, [cookies])

    if (jwt == null) {
        return (
            <Link target="_blank" href={authUrl} rel="noreferrer">
                Login
            </Link>
        )
    } else {
        return (
            <Link target="_blank" href={accountUrl} rel="noreferrer">
                Account
            </Link>
        )
    }
}

export default NavbarAuth
