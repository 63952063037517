import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";
import { CSVLink } from "react-csv";

import { ExecuteResponse } from "../responses";
import { getItems } from "../../utils/csv";

function SplittedFormExecute({
  handleClick,
  index,
  data,
  parameters,
  isExecuting,
  setIsExecuting,
}) {
  const theme = useTheme();
  const [hasError, setHasError] = useState(false);
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState([]);

  useEffect(() => {
    setIsExecuting(loading);
  }, [loading]);
  return (
    <Grid container spacing={1}>
      <Grid item sx={{ minWidth: "100px" }}>
        <CSVLink
          onClick={() => {
            setFileData(getItems(data, parameters));
          }}
          data={fileData}
          filename={"file" + index + ".csv"}
        >
          file{index}
        </CSVLink>{" "}
        :
      </Grid>
      <Grid item px={1}>
        <Button
          variant="contained"
          size="small"
          onClick={(event) => {
            handleClick(data, setHasError, setResponse, setLoading);
          }}
          sx={{
            backgroundColor: response
              ? alpha(
                  hasError ? theme.response.error : theme.response.success,
                  1
                )
              : "",
          }}
          disabled={loading || Boolean(!hasError && response) || isExecuting}
        >
          execute file{index}
        </Button>
      </Grid>
      <Grid item>
        <ExecuteResponse
          hasError={hasError}
          response={response}
          isLoading={loading}
        />
      </Grid>
    </Grid>
  );
}

export default SplittedFormExecute;
