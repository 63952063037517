import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { injected } from "../../../utils/connectors";
import { changeNetwork } from "../../../utils/changeNetwork";
import MenuItem from "@mui/material/MenuItem";

import { useIsCorrectNetwork } from "../../../hooks/useIsCorrectNetwork";
import { Box } from "@mui/system";

import MenuIcon from "@mui/icons-material/Menu";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { AppBar, Button, Toolbar } from "@mui/material";
import getCurrentNetwork from "../../../utils/getCurrentNetwork";
import { alpha, useTheme } from "@mui/material/styles";
import NavBarWalletInfo from "./NavBarWalletInfo";
import { ReadOnlyText, InputSelect } from "../../../components/inputs";

import NavBarMenu from "./NavBarMenu";
import NetworkSelect from "./NetworkSelect";
import { shortenAddressString } from "../../../utils/strings";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrentNetwork,
  setCurrentAccount,
  setIsAccountConnected,
  setIsCorrectNetwork,
  setIsClientReady,
} from "../../../redux/slices/WalletInfo.slice";

/**
 * NavBar
 * @param {onProviderMountCallBack} onProviderMount
 * @param {SDK} currentConfig - the current SDK selected
 * @param {function} setCurrentCofig - a function to update the current config
 * @returns {element} - renderers the navbar
 */
function NavBar({ onProviderMount, currentConfig, setCurrentConfig }) {
  const {
    active,
    account,
    chainId,
    activate,
    library: provider,
  } = useWeb3React();

  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    config,
    currentAccount,
    currentNetwork,
    isAccountConnected,
    isClientReady,
  } = useSelector((state) => state.WalletInfo);

  const { onAccountChange, onNetworkChange } = currentConfig;
  const { networks: configNetworkList } = currentConfig.configuration;

  const isCorrectNetwork = useIsCorrectNetwork(configNetworkList);

  const additionalSx = {
    backgroundColor: alpha(theme.bg.wallet, 0.4),
    minWidth: "120px",
    m: 1,
    border: isClientReady ? "" : "0.5px solid red",
    "& .MuiFormLabel-root.MuiInputLabel-root": {
      color: "white",
    },
  };

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;
    if (!ethereum) {
      console.log("Make sure you have Metamask installed!");
      return;
    } else {
      console.log("Wallet exists! We're ready to go!");
      try {
        await activate(injected);
      } catch (error) {
        console.log(error);
      }
    }
  };
  //get the network from the config file if that chain Id exists in the configNetworkList array

  const handleChange = (event) => {
    const network = getCurrentNetwork(configNetworkList, event.target.value);
    dispatch(setCurrentNetwork(network.chainId));

    changeNetwork(network);
    handleCloseWalletMenu();
  };

  useEffect(() => {
    checkWalletIsConnected();
  }, [active]);

  //updates the network everytime network changes
  //also updates the dropdown if user changes the network from metamasks and selects a valid network
  useEffect(() => {
    setNetwork();
    dispatch(setIsCorrectNetwork(isCorrectNetwork));
  }, [isCorrectNetwork]);

  const providerMounted = useRef(false);

  useEffect(() => {
    if (provider) {
      const fetchOnProviderMountResult = async () => {
        if (getCurrentNetwork(configNetworkList, chainId)) {
          const data = await onProviderMount(
            provider,
            getCurrentNetwork(configNetworkList, chainId)
          );
          dispatch(setIsClientReady(true));
        }

        // setIsClientReady(data);
      };

      if (active && provider && onProviderMount) {
        fetchOnProviderMountResult().catch(console.error);
      }
    }
  }, [provider]);

  useEffect(() => {
    if (isClientReady && onAccountChange) {
      onAccountChange();
    }
    dispatch(setIsAccountConnected(account ? true : false));
    dispatch(setCurrentAccount(account ? account : ""));
  }, [account]);

  useEffect(() => {
    if (isClientReady && onNetworkChange) {
      onNetworkChange(getCurrentNetwork(configNetworkList, chainId));
    }
  }, [chainId]);

  //sets the network to the current chain id from useWeb3React
  const setNetwork = () => {
    if (configNetworkList.find((network) => network.chainId === chainId))
      dispatch(setCurrentNetwork(chainId));
  };

  useEffect(() => {
    if (active && isAccountConnected && providerMounted && isCorrectNetwork) {
      dispatch(setIsClientReady(true));
    } else {
      dispatch(setIsClientReady(false));
    }
  }, [active, isAccountConnected, isCorrectNetwork]);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElWallet, setAnchorElWallet] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenWalletMenu = (event) => {
    setAnchorElWallet(event.currentTarget);
  };

  const handleCloseNavMenu = (event) => {
    dispatch(setIsClientReady(false));

    setCurrentConfig(
      event.target.value ? config[event.target.value] : currentConfig
    );
    setAnchorElNav(null);
  };

  const handleCloseWalletMenu = () => {
    setAnchorElWallet(null);
  };

  const mainAccounts = isClientReady ? currentConfig.getContractAddress() : "";
  const otherAccounts = isClientReady
    ? currentConfig.getOtherContractAddress()
    : [];
  return (
    <AppBar sx={{ backgroundColor: alpha("#305C53", 0.7) }}>
      <Toolbar>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", md: "none" },
            justifyContent: "space-between",
          }}
        >
          <NavBarMenu
            Icon={MenuIcon}
            anchorEl={anchorElNav}
            handleOpen={handleOpenNavMenu}
            handleClose={handleCloseNavMenu}
          >
            {Object.keys(config).map((item) => (
              <MenuItem key={item}>
                <Button
                  value={item}
                  onClick={handleCloseNavMenu}
                  sx={{
                    borderRadius: 0,
                    color: "white",
                    borderBottom:
                      config[item] === currentConfig ? "1px solid white " : "",
                  }}
                  fullWidth
                >
                  {item}
                </Button>
              </MenuItem>
            ))}
          </NavBarMenu>

          <NavBarMenu
            Icon={AccountBalanceWalletIcon}
            anchorEl={anchorElWallet}
            handleOpen={handleOpenWalletMenu}
            handleClose={handleCloseWalletMenu}
          >
            <MenuItem>
              <InputSelect
                isNative={true}
                label="Contract Addressess"
                additionalSx={{ width: "100%", ...additionalSx }}
              >
                <optgroup label="main">
                  <option>{shortenAddressString(mainAccounts)}</option>
                </optgroup>
                {otherAccounts.length !== 0 ? (
                  <optgroup label="other">
                    {otherAccounts.map((contractAddress, index) => (
                      <option key={index}>
                        {shortenAddressString(contractAddress)}
                      </option>
                    ))}
                  </optgroup>
                ) : (
                  ""
                )}
              </InputSelect>
            </MenuItem>
            <MenuItem>
              <ReadOnlyText
                text={account}
                label="User Address"
                additionalSx={{ width: "100%", ...additionalSx }}
              />
            </MenuItem>
            <MenuItem>
              <NetworkSelect
                label="Networks"
                isConnected={isCorrectNetwork}
                networkList={configNetworkList}
                handleChange={handleChange}
                currentNetwork={currentNetwork}
                additionalSx={{ width: "100%", ...additionalSx }}
              />
            </MenuItem>
          </NavBarMenu>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },

            justifyContent: "space-between",
          }}
        >
          <Box display="flex" alignItems={"center"}>
            {Object.keys(config).map((item) => (
              <Button
                key={item}
                value={item}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "white",
                  borderRadius: 0,
                  fontWeight: "bold",
                  fontSize: "1rem",
                  "&:hover": {
                    backgroundColor: "#305C53",
                  },

                  borderBottom:
                    config[item] === currentConfig ? "1px solid white " : "",
                }}
              >
                {item}
              </Button>
            ))}
          </Box>
          <Box>
            <NavBarWalletInfo
              isClientReady={isClientReady}
              isCorrectNetwork={isCorrectNetwork}
              currentNetwork={currentNetwork}
              networks={configNetworkList}
              handleChange={handleChange}
              account={account}
              mainAccounts={mainAccounts}
              otherAccounts={otherAccounts}
              additionalSx={additionalSx}
            />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
