import React from "react"
import {CircularProgress, Typography, Box} from "@mui/material"
import {alpha} from "@mui/material/styles"
import ReactJson from "react-json-view"

function ExecuteResponse({hasError, isLoading, response}) {
    return (
        <Typography
            variant="subtitle1"
            color={isLoading ? "primary" : hasError ? "error" : "success.main"}
            sx={{
                px: {xs: 1, sm: 2},
                backgroundColor: !isLoading
                    ? alpha(hasError ? "#e5a5a5" : "#60d95b", 0.2)
                    : "none",
                overflow: "hidden",
                fontWeight: "bold",
                whiteSpace: "pre-wrap",
            }}>
            {!isLoading && response
                ? `${hasError ? "Error" : "Response"}: ${
                    typeof response !== "string" ? (hasError ? response.message : "Success") : response
                }`
                : ""}
            {isLoading && (
                <Box>
                    <CircularProgress size={16}/> Loading
                </Box>
            )}

            {!isLoading && (response ? typeof response !== "string" : false) && (
                <Typography variant="subtitle">
                    <ReactJson
                        src={JSON.parse(JSON.stringify(response))}
                        collapsed={true}
                        name={null}
                    />
                </Typography>
            )}
        </Typography>
    )
}

export default ExecuteResponse
