const { ethers } = require("ethers");
export const changeNetwork = async (net) => {
  const { ethereum } = window;
  if (ethereum.networkVersion !== net.chainId) {
    // RPC expects 0xnon-zero hexdemical, but with custom networks when
    //converting chainID to hex it givces 0x0 so causes an error
    //So i created this hexChain and while loop to remove the 0 after the x at 0x0 so it will be a valid hex code
    let hexChain = ethers.utils.hexlify(net.chainId);
    let validHex = false;
    while (!validHex) {
      if (hexChain.charAt(2) === "0") {
        hexChain = hexChain.slice(0, 2) + hexChain.slice(2 + 1);
      } else {
        validHex = true;
      }
    }

    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: hexChain }],
      });
    } catch (err) {
      console.log(err);
      // This error code indicates that the chain has not been added to MetaMask.
      if (err.code === 4902) {
        return await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: net.name,
              chainId: hexChain,
              nativeCurrency: net.currency,
              rpcUrls: net.rpc,
            },
          ],
        });
      }
    }
  }
};
