import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function SDKPanelDescription({ configuration, isClientReady }) {
  return (
    <Box sx={{ mt: 12, mx: { xs: 1.5, sm: 4 }, color: "white" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight={"bold"}>
            {configuration.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{}}>
            {configuration.description}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SDKPanelDescription;
