import React, { useEffect, useContext, useState, useMemo } from "react";
import {
  Accordion,
  Grid,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import * as yup from "yup";
import { createYupSchema } from "../../utils/yupSchemaCreator";
import { Formik } from "formik";

import FormFieldList from "./FormFieldList";
import getParameters from "../../utils/getParameters";
import MyContext from "../../context/myContext";
import SplittedForm from "./SplittedForm";
import FormExecute from "./FormExecute";
import notify from "../../utils/notify";
import getOrderedArray from "../../utils/getOrderedArray";
import { splitRowsPerTransaction } from "../../utils/csv";

function Form({ method }) {
  const theme = useTheme();
  const { methodHandling } = useContext(MyContext);
  const [hasError, setHasError] = useState(false);
  const [response, setResponse] = useState();
  const parametersList = useMemo(
    () => getParameters(method.parameters),
    [method.parameters]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [dataToSplit, setDataToSplit] = useState();
  const [isSplitTransaction, setIsSplitTransaction] = useState(false);
  const [isSplitRendered, setIsSplitRendered] = useState(false);
  const [noOfTransactions, setNoOfTransactions] = useState(0);
  const [fileName, setFileName] = useState("");
  const [values, setValues] = useState();
  const [roleFound, setRoleFound] = useState([]);

  useEffect(() => {
    if (method.restricted.length > 0 && methodHandling) {
      setRoleFound([]);

      method.restricted.forEach((role) =>
        methodHandling
          .hasRole(role.role)
          .then((res) => {
            if (res) setRoleFound((prev) => [...prev, role]);
          })
          .catch((error) => console.error(error))
      );
    }
  }, [methodHandling, method.restricted]);

  const isMethodDisabled = useMemo(() => {
    return method.restricted.length > 0 && roleFound.length < 1 ? true : false;
  }, [method, roleFound]);

  const defaultProps = {
    style: {
      backgroundColor: alpha(theme.bg.method, 0.8),
      border: `1px solid ${theme.border.method}`,
    },
  };

  const initialValues = {};
  parametersList.forEach((item) => {
    initialValues[item.id] = item.type === "boolean" ? false : "";
  });

  const yepSchema = parametersList.reduce(createYupSchema, {});

  const validateSchema = yup.object().shape(yepSchema);

  const executeMethod = (
    parameters,
    setHasError,
    setResponse,
    setIsLoading
  ) => {
    setIsLoading(true);

    methodHandling
      .executeMethod(method.method, parameters)
      .then((res) => {
        const formatedResponse = method.formatResponse(res);
        setIsLoading(false);
        setHasError(false);

        setResponse(formatedResponse);
        notify(
          "success",
          typeof formatedResponse === "string"
            ? "Response: " + formatedResponse
            : "Success"
        );
      })
      .catch((er) => {
        const formatedError = methodHandling.formatError(er);
        setIsLoading(false);
        notify(
          "error",
          formatedError.message ? formatedError.message : formatedError
        );
        setHasError(true);
        setResponse(formatedError);
      });
  };

  return (
    //for each method it generates an accordion summary and generates the parameters componnent passing it the parameters of the method
    <Accordion
      sx={{
        mb: 4,
      }}
      {...defaultProps}
      disabled={isMethodDisabled}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container alignItems={"flex-end"} spacing={1}>
          <Grid item>
            <Typography variant="h5">{method.name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" sx={{ color: "gray" }}>
              {method.description}
            </Typography>
          </Grid>
          <Grid item>
            {method.restricted.map((role, index) => (
              <Typography
                key={index}
                variant="subtitle"
                sx={{
                  color: isMethodDisabled ? "red" : "blue",
                  fontWeight: isMethodDisabled ? "bold" : "normal",
                }}
              >
                {role.name}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={(values) => {
            try {
              setIsSplitRendered(false);
              const orderedArray = parametersList.map(parameter => getOrderedArray(values, parameter));
              if (isSplitTransaction) {
                //if split transaction generates data based on csv string
                setValues(values);
                setDataToSplit(
                  splitRowsPerTransaction(
                    values,
                    noOfTransactions,
                    parametersList
                  )
                );
                setIsSplitRendered(true);
              } else {
                executeMethod(
                  orderedArray,
                  setHasError,
                  setResponse,
                  setIsLoading
                );
              }
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <FormFieldList
                parameters={parametersList}
                formik={formik}
                noOfTransactions={noOfTransactions}
                setIsSplitTransaction={setIsSplitTransaction}
                setNoOfTransactions={setNoOfTransactions}
                fileName={fileName}
                setFileName={setFileName}
              />
              <FormExecute
                hasError={hasError}
                response={response}
                formik={formik}
                isLoading={isLoading}
              />
              <SplittedForm
                parameters={parametersList}
                isRendered={isSplitRendered}
                setRendered={setIsSplitRendered}
                noOfTransactions={noOfTransactions}
                data={dataToSplit}
                methodName={method.name}
                fileName={fileName}
                executeMethod={executeMethod}
                values={values}
              />
            </form>
          )}
        </Formik>
      </AccordionDetails>
    </Accordion>
  );
}

export default Form;
