import { Dialog, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Box } from "@mui/system";
import notify from "../../utils/notify";

function DragNDrop({ onClose, open, fileType, setFileName }) {
  const processCSV = (str, delim = ",") => {
    const headers = str.slice(0, str.indexOf("\n")).split(delim);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    const newArray = rows.map((row) => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        if (header !== "") {
          obj[header] = values[i];
        }
        return obj;
      }, {});

      return eachObject;
    });

    handleClose(newArray);
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      if (file.name.substring(file.name.length - 4) === fileType) {
        setFileName(file.name);
        const reader = new FileReader();
        reader.onabort = () => notify("error", "File reading was aborted");
        reader.onerror = () => notify("error", "File reading has failed");
        reader.onload = () => {
          processCSV(reader.result);
        };
        reader.readAsText(file);
      } else {
        notify("error", "Wrong File Type Selected: Only Accepts .csv files");
      }
    });
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  const handleClose = (value) => {
    onClose(value);
  };
  //For csv file url functionality
  // const handleLoad = async (event) => {
  //   fetch(linkValue, {
  //     headers: {
  //       Accept: "text/csv",
  //     },
  //   })
  //     .then(async (response) => {
  //       const res = await response.text();
  //       console.log("response", res);
  //     })
  //     .catch((err) => Promise.reject(err));
  // };
  // const [linkValue, setLinkValue] = useState("");
  return (
    <Dialog onClose={() => handleClose(null)} open={open}>
      <Box sx={{ p: { xs: 2, sm: 4 } }}>
        {/* <Box sx={{ p: { xs: 1, sm: 4 } }}>
          <TextField
            placeholder="www.myCSVfile.com/myfile.csv"
            type="url"
            value={linkValue}
            onChange={(e) => setLinkValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <Button variant="outlined" size="small" onClick={handleLoad}>
                  load
                </Button>
              ),
            }}
            fullWidth
          />
        </Box> */}
        <Box
          sx={{
            p: { xs: 2, sm: 4 },
            border: "1px dashed gray",
            borderRadius: "10px",
            "&:hover": {
              backgroundColor: "#f2f2f2",
            },
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography variant="h3" align="center">
              Drop the file here ...
            </Typography>
          ) : (
            <Typography variant="h3" align="center">
              Drag 'n' drop the file here, <br />
              OR
              <br /> <b>CLICK</b> to select the file
            </Typography>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}

export default DragNDrop;
