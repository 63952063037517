import React from "react";
import { TextField } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";

import { shortenAddressString } from "../../utils/strings";

function ReadOnlyText({ text, label, additionalSx = {} }) {
  const theme = useTheme();
  return (
    <TextField
      size="small"
      variant="filled"
      label={label}
      value={shortenAddressString(text)}
      InputLabelProps={{ shrink: true }}
      inputProps={{ style: { color: text ? "white" : "red" } }}
      sx={{ width: "120px", ...additionalSx }}
    />
  );
}

export default ReadOnlyText;
