import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import FormField from "./FormField";

function FormFieldList({
  parameters,
  formik,
  setIsSplitTransaction,
  fileName,
  setFileName,
  noOfTransactions,
  setNoOfTransactions,
}) {
  const [hasCSV, setHasCSV] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    parameters.forEach((parameter) => {
      !hasCSV && parameter.type === "csv" ? setHasCSV(true) : setHasCSV(false);
    });
  }, [parameters]);

  return (
    //creates a grid layout based on whether it has CSV file type or not and then

    <Grid
      container
      sx={{
        mb: parameters.length !== 0 ? 2 : 0,
        backgroundColor: theme.bg.parameters,
      }}
      justifyContent="space-between"
      rowSpacing={2}
    >
      {hasCSV && (
        <>
          {parameters.map(
            (parameter, index) =>
              parameter.type === "csv" && (
                <Grid item xs={12} sm={6} key={index}>
                  <FormField
                    parameter={parameter}
                    index={index}
                    formik={formik}
                    setIsSplitTransaction={setIsSplitTransaction}
                    noOfTransactions={noOfTransactions}
                    setNoOfTransactions={setNoOfTransactions}
                    fileName={fileName}
                    setFileName={setFileName}
                  />
                </Grid>
              )
          )}
          <Grid item xs={12} sm={5.5}>
            <Grid container rowSpacing={4}>
              {parameters.map(
                (parameter, index) =>
                  parameter.type !== "csv" && (
                    <Grid item key={index} xs={12}>
                      <FormField
                        parameter={parameter}
                        index={index}
                        formik={formik}
                        setIsSplitTransaction={setIsSplitTransaction}
                        noOfTransactions={noOfTransactions}
                        setNoOfTransactions={setNoOfTransactions}
                        fileName={fileName}
                        setFileName={setFileName}
                      />
                    </Grid>
                  )
              )}
            </Grid>
          </Grid>
        </>
      )}
      {!hasCSV && (
        <Grid container columnSpacing={4} rowGap={4}>
          {parameters.map((parameter, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <FormField
                parameter={parameter}
                index={index}
                formik={formik}
                setIsSplitTransaction={setIsSplitTransaction}
                noOfTransactions={noOfTransactions}
                setNoOfTransactions={setNoOfTransactions}
                fileName={fileName}
                setFileName={setFileName}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
}

export default FormFieldList;
