import { MenuItem } from "@mui/material";
import React from "react";
import { InputSelect } from "../../../components/inputs";
import { alpha, useTheme } from "@mui/material/styles";
function NetworkSelect({
  isConnected,
  currentNetwork,
  networkList,
  label,
  handleChange,
  additionalSx = {},
}) {
  const theme = useTheme();
  return (
    <InputSelect
      label={label}
      isCorrectNetwork={isConnected}
      networks={networkList}
      handleChange={handleChange}
      currentNetwork={currentNetwork}
      additionalSx={additionalSx}
    >
      {networkList.map((network) => (
        <MenuItem key={network.name} value={network.chainId}>
          {network.name}
        </MenuItem>
      ))}
    </InputSelect>
  );
}

export default NetworkSelect;
