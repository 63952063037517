import { configureStore } from "@reduxjs/toolkit";
import FormGroupsReducer from "./slices/FormGroups";
import WalletInfoReducer from "./slices/WalletInfo.slice";
export const store = configureStore({
  reducer: {
    FormGroups: FormGroupsReducer,
    WalletInfo: WalletInfoReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
