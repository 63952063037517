import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, useTheme } from "@mui/material";

import Form from "./Form";
import BackgroundImage from "../../assets/bg-effect.png";

function FormGroup({ methodGroup }) {
  const theme = useTheme();

  return (
    // for each item in the methodGroup it creates a action summary and then in the action details loops through all its methods
    //creating a method component for each item
    <Accordion
      sx={{
        border: `1px solid ${theme.border.methodGroup}`,
        backgroundImage: `url(${BackgroundImage})`,
        my: { xs: 2, sm: 4 },
      }}
      defaultExpanded
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          color: theme.color.methodGroup,
        }}
      >
        <Grid container alignItems={"flex-end"} spacing={1}>
          <Grid item>
            <Typography variant="h4" fontWeight={"bold"}>
              {methodGroup.name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              sx={{
                color: "white",
              }}
            >
              {methodGroup.description}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {methodGroup.methods.map((method, index) => (
          <Form key={index} method={method}></Form>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default FormGroup;
