const shortenAddressString = (account) => {
  return account
    ? account.length > 9
      ? account.substring(0, 5) +
        "..." +
        account.substring(account.length - 4, account.length)
      : account
    : "Account not found";
};
export default shortenAddressString;
