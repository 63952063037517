import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function InputSelect({
  label,
  isCorrectNetwork,
  currentNetwork,
  handleChange,
  additionalSx = {},
  children,
  isNative = false,
}) {
  return (
    <FormControl sx={{ ...additionalSx }} size="small" variant="filled">
      <InputLabel
        shrink={true}
        sx={{ color: isCorrectNetwork ? "black" : "red" }}
      >
        {label}
      </InputLabel>
      <Select
        native={isNative}
        value={
          isCorrectNetwork ? (currentNetwork ? currentNetwork : "") : "" || ""
        }
        onChange={handleChange}
        sx={{
          color: "white",
          "& .MuiSvgIcon-root.MuiSelect-icon": {
            color: "white",
          },
          "& .MuiSvgIcon-root.MuiNativeSelect-icon": {
            color: "white",
          },
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
}

export default InputSelect;
