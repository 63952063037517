import { toast } from "react-toastify";
const notify = (type, message) => {
  switch (type) {
    case "error":
      return toast.error(message);
    case "success":
      return toast.success(message);
    default:
      return toast(message);
  }
};
export default notify;
