import "./App.css"

import {
    DelysiumAssets,
    DelysiumCollectibles,
    DelysiumStore,
    DMA,
    TokenWithdrawal
} from "@delysium/client"
import {SDKPanel} from "./lib"

const assets = new DelysiumAssets()
const collectibles = new DelysiumCollectibles()
const store = new DelysiumStore()
const dma = new DMA()
const tokenWithdrawal = new TokenWithdrawal()

const onAccountChange = (sdk) => {
    sdk.syncSigner()
}
const onNetworkChange = (sdk, network) => {
    sdk.syncNetwork(network)
}
const onProviderMount = async (provider, network) => {
    const readyProvider = await provider
    let collectiblesPromise = !network
        ? false
        : new Promise((resolve, reject) => {
            collectibles
                .initialize({
                    network: network ? network.key : 1,
                    provider: readyProvider,
                })
                .then(() => {
                    resolve(true)
                })
                .catch((error) => {
                    console.error("error:", error)
                    resolve(false)
                })
        })
    let assetsPromise = !network
        ? false
        : new Promise((resolve, reject) => {
            assets
                .initialize({
                    network: network ? network.key : 1,
                    provider: readyProvider,
                })
                .then(() => {
                    resolve(true)
                })
                .catch((error) => {
                    console.error("error:", error)
                    resolve(false)
                })
        })
    let storePromise = !network
        ? false
        : new Promise((resolve, reject) => {
            store
                .initialize({
                    network: network ? network.key : 1,
                    provider: readyProvider,
                })
                .then(() => {
                    resolve(true)
                })
                .catch((error) => {
                    console.error("error:", error)
                    resolve(false)
                })
        })
    let dmaPromise = !network
        ? false
        : new Promise((resolve, reject) => {
            dma
                .initialize({
                    network: network ? network.key : 1,
                    provider: readyProvider,
                })
                .then(() => {
                    resolve(true)
                })
                .catch((error) => {
                    console.error("error:", error)
                    resolve(false)
                })
        })

    let tokenWithdrawalPromise = !network
        ? false
        : new Promise((resolve, reject) => {
            tokenWithdrawal
                .initialize({
                    network: network ? network.key : 1,
                    provider: readyProvider,
                })
                .then(() => {
                    resolve(true)
                })
                .catch((error) => {
                    console.error("error:", error)
                    resolve(false)
                })
        })

    return Promise.all([
        collectiblesPromise,
        assetsPromise,
        storePromise,
        dmaPromise,
        tokenWithdrawalPromise
    ])
}

const config = {
    collectibles: {
        configuration: collectibles.config,
        onAccountChange: () => collectibles.syncSigner(),
        onNetworkChange: (network) => collectibles.syncNetwork(network),
        // onProviderMount: async (provider, network) => {
        //   const readyProvider = await provider;
        //   return new Promise((resolve, rejects) => {
        //     collectibles
        //       .initialize({
        //         network: network ? network.key : 1,
        //         provider: readyProvider,
        //       })
        //       .then(() => {
        //         resolve(true);
        //       })
        //       .catch((error) => {
        //         console.error("error:", error);
        //         resolve(false);
        //       });
        //   });
        // },
        executeMethod: (method, parameters) => {
            return collectibles.sdk[method](...parameters)
        },
        getContractAddress: () => {
            return collectibles.sdk.contractAddress
        },
        getOtherContractAddress: () => {
            return []
        },
        hasRole: (role) => {
            return collectibles.sdk.gotRole(role)
        },
    },

    assets: {
        configuration: assets.config,
        onAccountChange: () => assets.syncSigner(),
        onNetworkChange: (network) => assets.syncNetwork(network),
        executeMethod: (method, parameters) => {
            return assets.sdk[method](...parameters)
        },
        getContractAddress: () => {
            return assets.sdk.contractAddress
        },
        getOtherContractAddress: () => {
            return []
        },
        hasRole: (role) => {
            return assets.sdk.gotRole(role)
        },
    },
    store: {
        configuration: store.config,
        onAccountChange: () => store.syncSigner(),
        onNetworkChange: (network) => store.syncNetwork(network),

        executeMethod: (method, parameters) => {
            return store.sdk[method](...parameters)
        },
        getContractAddress: () => {
            return store.sdk.contractAddress
        },
        getOtherContractAddress: () => {
            return [store.sdk.assets.contractAddress]
        },
        hasRole: (role) => {
            return store.sdk.gotRole(role)
        },
    },
    dma: {
        configuration: dma.config,
        onAccountChange: () => dma.syncSigner(),
        onNetworkChange: (network) => dma.syncNetwork(network),

        executeMethod: (method, parameters) => {
            return dma.sdk[method](...parameters)
        },
        getContractAddress: () => {
            return dma.sdk.contractAddress
        },
        getOtherContractAddress: () => {
            return []
        },
        hasRole: (role) => {
            // return null;
            return dma.sdk.gotRole(role)
        },
    },
    tokenWithdrawal: {
        configuration: tokenWithdrawal.config,
        onAccountChange: () => tokenWithdrawal.syncSigner(),
        onNetworkChange: (network) => tokenWithdrawal.syncNetwork(network),

        executeMethod: (method, parameters) => {
            return tokenWithdrawal.sdk[method](...parameters)
        },
        getContractAddress: () => {
            return tokenWithdrawal.sdk.contractAddress
        },
        getOtherContractAddress: () => {
            return []
        },
        hasRole: (role) => {
            return tokenWithdrawal.sdk.gotRole(role)
        },
    },
}

/**
 * THIS IS IN THE APP JS FILE
 * @param {onProviderMountCallBack} onProviderMount
 * @param {config} config
 */
function App() {
    return (
        <SDKPanel
            config={config}
            onAccountChange={onAccountChange}
            onNetworkChange={onNetworkChange}
            onProviderMount={onProviderMount}
        >
            {console.log(config)}
        </SDKPanel>
    )
}

export default App
