import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  config: {},
  currentConfig: {},
  currentAccount: "",
  currentNetwork: 0,
  isAccountConnected: false,
  isCorrectNetwork: false,
  isClientReady: false,
  formGroupList: [],
};

export const slice = createSlice({
  name: "WalletInfo",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setCurrentConfig: (state, action) => {
      state.currentConfig = action.payload;
    },
    setCurrentAccount: (state, action) => {
      state.currentAccount = action.payload;
    },
    setCurrentNetwork: (state, action) => {
      state.currentNetwork = action.payload;
    },
    setIsAccountConnected: (state, action) => {
      state.isAccountConnected = action.payload;
    },
    setIsCorrectNetwork: (state, action) => {
      state.isCorrectNetwork = action.payload;
    },
    setIsClientReady: (state, action) => {
      state.isClientReady = action.payload;
    },
    setFormGroupList: (state, action) => {
      state.formGroupList = action.payload;
    },
  },
});

export const {
  setCurrentNetwork,
  setCurrentAccount,
  setConfig,
  setCurrentConfig,
  setIsAccountConnected,
  setIsCorrectNetwork,
  setIsClientReady,
  setFormGroupList,
} = slice.actions;

export default slice.reducer;
