import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Button, Dialog, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import SplittedFormExecute from "./SplittedFormExecute";
import { getItems } from "../../utils/csv";

function SplittedForm({
  parameters,
  isRendered,
  noOfTransactions,
  data,
  setRendered,
  fileName,
  executeMethod,
  values,
}) {
  const [isExecuting, setIsExecuting] = useState(false);
  const theme = useTheme();

  const handleClick = (data, setHasError, setResponse, setLoading) => {
    let tempArray = [];

    const item = getItems(data, parameters);

    //co
    parameters.map((parameter) => {
      if (parameter.type !== "csv") {
        tempArray.push(values[parameter.name]);
      } else tempArray = [...tempArray, item];
    });

    setLoading(true);

    executeMethod(tempArray, setHasError, setResponse, setLoading);
  };
  const handleClose = () => {
    setRendered(false);
  };
  let fileCounter = 1;

  return (
    <Dialog open={isRendered} maxWidth="90vw">
      <Box
        sx={{
          py: { xs: 1, sm: 4 },
          px: { xs: 3, sm: 4 },
          maxWidth: "90vw",
          backgroundColor: theme.bg.parameter,
        }}
      >
        <Grid container rowSpacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              size="small"
              color="warning"
              onClick={handleClose}
            >
              Close
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{fileName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Number of Transactions per file: {noOfTransactions}
            </Typography>
          </Grid>

          {data &&
            Object.keys(data).map((key, index) =>
              key.includes("file") ? (
                <Grid item xs={12} key={index}>
                  <SplittedFormExecute
                    index={fileCounter++}
                    data={data[key]}
                    handleClick={handleClick}
                    parameters={parameters}
                    isExecuting={isExecuting}
                    setIsExecuting={setIsExecuting}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} key={index}>
                  {key} : {data[key]}
                </Grid>
              )
            )}
        </Grid>
      </Box>
    </Dialog>
  );
}

export default SplittedForm;
