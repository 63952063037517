import { Button, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import React from "react";
import { ExecuteResponse } from "../responses";

function FormExecute({ hasError, response, isLoading, formik }) {
  const theme = useTheme();

  return (
    <Grid container justifyContent={"space-between"} rowSpacing={1}>
      <Grid item xs={12}>
        <ExecuteResponse
          hasError={hasError}
          response={response}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item container justifyContent="flex-end">
        <Button
          variant="contained"
          type="submit"
          disabled={(!formik.isValid && formik.dirty) || isLoading}
          sx={{
            backgroundColor: theme.bg.button.primary,

            "&:hover": {
              backgroundColor: theme.bg.button.secondary,
            },
          }}
        >
          Run
        </Button>
      </Grid>
    </Grid>
  );
}

export default FormExecute;
